import { useEffect, useRef, useState } from "react";

interface IProps {
    carouselList: ICarousel[];
}

interface ICarousel {
    title: string;
    imageUrl: string;
    style?: React.CSSProperties;
    description?: string;
    href?: string;
}

const CenterImgCarousel = ({ carouselList }: IProps) => {
    const [current, setCurrent] = useState<number>(0);
    const [isDragging, setIsDragging] = useState<boolean>(false); // 드래그 상태
    const [startX, setStartX] = useState<number>(0); // 드래그 시작 X 좌표
    const [dragOffset, setDragOffset] = useState<number>(0); // 드래그 이동량
    const [autoSlide, setAutoSlide] = useState<boolean>(true);  // 자동 슬라이드 상태
    const ref = useRef<HTMLDivElement>(null);
    const calculateTranslateX = () => {
        if (current === 0) { return 0; }
        const baseValue = current * 90.2;
        const extraGap = 2.66 * current * 0.5;
        return baseValue + extraGap;
    };

    const startAutoSlide = () => {
        return setInterval(() => {
            if (autoSlide) {
                setCurrent((curr) => (curr === carouselList.length - 1 ? 0 : curr + 1));
            }
        }, 3000);
    };

    useEffect(() => {
        const interval = startAutoSlide();
        return () => clearInterval(interval); // 컴포넌트 unmount 시 타이머 제거
    }, [carouselList.length, autoSlide]);

    // 드래그 시작
    const onDragStart = (e: React.MouseEvent | React.TouchEvent) => {
        const clientX = e instanceof MouseEvent ? e.clientX : (e as React.TouchEvent).touches?.[0].clientX;
        setIsDragging(true);
        setStartX(clientX);
        setAutoSlide(false); // 드래그 시작 시 자동 슬라이드 멈춤
    };

    // 드래그 중
    const onDragMove = (e: React.MouseEvent | React.TouchEvent) => {
        if (!isDragging) return;
        const clientX = e instanceof MouseEvent ? e.clientX : (e as React.TouchEvent).touches?.[0].clientX;
        setDragOffset(clientX - startX);
    };

    // 드래그 종료
    const onDragEnd = () => {
        if (!isDragging) return;
        setIsDragging(false);
        const threshold = 100; // 이동량 기준 (100px 이상 이동 시 슬라이드 변경)
        if (dragOffset > threshold) {
            setCurrent((prev) => (prev === 0 ? carouselList.length - 1 : prev - 1));
        } else if (dragOffset < -threshold) {
            setCurrent((prev) => (prev === carouselList.length - 1 ? 0 : prev + 1));
        }
        setDragOffset(0); // 초기화
        setAutoSlide(true); // 드래그 종료 후 자동 슬라이드 재개
    };
    return <>
        <div style={{ display: "flex", overflowX: 'hidden', }} ref={ref}
            onMouseDown={onDragStart} // 마우스 이벤트
            onTouchStart={onDragStart} // 터치 이벤트
            onMouseMove={onDragMove} // 마우스 이벤트
            onTouchMove={onDragMove} // 터치 이벤트
            onMouseUp={onDragEnd} // 마우스 이벤트
            onTouchEnd={onDragEnd} // 터치 이벤트
            onMouseLeave={onDragEnd} // 마우스가 영역을 벗어날 때도 드래그 종료
        >
            <div style={{
                display: "flex", transform: `translateX(-${calculateTranslateX() - (dragOffset / window.innerWidth) * 100}%)`,
                transition: isDragging ? "none" : "transform 0.5s ease", // 드래그 중에는 트랜지션 없이 즉시 이동 
                width: '100%', marginInline: '10px'
            }}>
                {carouselList.map((carousel, idx) => <div key={idx} style={{ minWidth: '92.4%', }}>
                    <div style={{ borderRadius: '8px', paddingTop: '59.2%', marginInline: '2.66%', backgroundImage: `url(${carousel.imageUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', gap: '4px', paddingTop: '12px', paddingLeft: '2%' }}>
                        <div className="sub-title/st3" style={{ whiteSpace: 'pre', color: 'var(--basewhite)' }}>
                            {carousel.title}
                        </div>
                        <div className="body/b2" style={{ whiteSpace: 'pre', color: 'var(--basewhite)' }}>
                            {carousel.description}
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
        <div style={{ width: '100%', display: 'flex', marginTop: '24px', justifyContent: 'center', gap: '7px' }}>
            {carouselList?.map((_, idx) => <div key={idx} style={{ width: `8px`, height: '8px', borderRadius: '50%', backgroundColor: current === idx ? 'var(--basewhite)' : 'var(--secondarys700)' }} onClick={() => setCurrent(idx)} />)}
        </div>
    </>
}

export { CenterImgCarousel }