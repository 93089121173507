import { useEffect, useRef, useState } from "react";
import { ReactComponent as PositivehotelLogoBig } from '../../../assets/images/positivehotelLogoBig.svg'
import { ReactComponent as ArrowDown } from "../../../assets/images/icon/arrow-down.svg";

const MainPremiumMembershipContents = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [currentDeviceBuildVersion, setCurrentDeviceBuildVersion] = useState<boolean>(false);
    const [topHeight, setTopHeight] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (window.native_yoga_bridge) {
            window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }));
            window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
                if (Number(currentVersion.split('.')[0]) >= 8) {
                    setCurrentDeviceBuildVersion(true)
                    window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getStatusBarTopHeight', data: {} }));
                    window.getStatusBarTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion(true) }
                }
            }
        }
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
            window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }))

            window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
                if (Number(currentVersion.split('.')[0]) >= 8) {
                    window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getSafeAreaTopHeight', data: {} }))
                    window.getSafeAreaTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion(true); }
                }
            }
        }
    }, [])

    const handleScroll = (): void => {
        if (scrollContainerRef.current) {
        };
    }


    return (
        <div style={{ position: 'relative', width: '100%', height: '100vh', overflow: 'hidden' }}>
            <video
                ref={videoRef}
                poster={`https://img.positivehotel.io/2024/12/17/membership-info.webp`}
                style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', height: '100%', objectFit: 'cover', transform: 'translate(-50%, -50%)' }}
                src={'https://img.positivehotel.io/2024/7/23/-5e28-4019-bd6a-fc1939ec37d9.mp4'} autoPlay loop muted playsInline
            />
            <div style={{ position: 'fixed', zIndex: 4, backgroundColor: "transparent", height: "50vh", width: "100%", pointerEvents: "none" }}></div>
            <div style={{ position: 'relative', zIndex: 2 }} onScrollCapture={handleScroll}>
                <div ref={scrollContainerRef} style={{ fontWeight: 600, fontSize: '14px', lineHeight: "24px", color: 'white', textAlign: 'center', padding: '40vh 20px 65vh 20px', height: '50px', overflow: 'scroll' }}>
                    <div style={{ marginBottom: "24px", top: currentDeviceBuildVersion ? (Number(topHeight) ?? 0) + 15 : 15, left: 0, width: "100%" }}>
                        <PositivehotelLogoBig />
                    </div>
                    <div style={{ height: "54px", width: "100%", marginBottom: "24px", }}>

                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5vh" }}>
                        <div>
                            파지티브호텔은 건강한 식문화와 요가, 명상을 결합한
                            웰니스 프로그램을 통해 새로운 형태의 웰니스를 제시합니다
                            <br /><br />
                            우리가 추구하는 웰니스는 건강한 음식을 섭취하며
                            매일 규칙적으로 운동하는 삶을 기본으로 합니다
                        </div>
                        <div>
                            파지티브호텔에서 내면과 외면의 밸런스를 찾으며
                            지속 가능한 웰니스 라이프를 경험해 보세요
                        </div>
                        <div>
                            커뮤니티를 기반으로 다양한 취향을 지닌 사람들이 만나
                            진정한 행복에 대해 교류하며 가치를 나누는 과정에서
                            삶이 점점 나아지는 의식적 진화를 추구합니다
                        </div>
                    </div>
                </div>
            </div>
            <ArrowDown width={16} height={16} style={{ position: "fixed", bottom: "10vw", left: "50vw" }} stroke={"#FFFFFF"} />
        </div>
    )
}
export default MainPremiumMembershipContents