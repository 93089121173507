import { useRef } from "react";
import { BrandPcBody } from "./body";
import { BrandPcHeader } from "./header";
import { TTargetName } from "./model";
import { BrandPcFooter } from "./footer";
import { ScrollTopBtn } from "../../../../shared/button/scrollTopBtn";
import './index.css';

const BrandPc = () => {
    const childRef = useRef<{ scrollToDiv: (target: TTargetName) => void }>(null);
    const handleScrollTo = (target: TTargetName) => childRef.current?.scrollToDiv(target);

    return <div className="brand-pc-wrapper">
        <div className="brand-pc-container">
            <BrandPcHeader className='' handleScrollTo={handleScrollTo} />
            <BrandPcBody ref={childRef} />
            <BrandPcFooter />
            <ScrollTopBtn className="brand-pc-scroll-top-btn" />
        </div>
    </div>
}

export { BrandPc };