interface ICarousel {
    title: string;
    imageUrl: string;
}

interface IBrandTextCardMo {
    mainContent: string;
    subContent: string;
    styles?: { wrapper?: React.CSSProperties, container?: React.CSSProperties, main?: React.CSSProperties, sub?: React.CSSProperties }
}

interface ISquareImgCard {
    title: string;
    imgUrl: string;
}

interface IBrandCenterCardMo {
    imageUrl: string;
    title: string;
    description: string;
    style?: React.CSSProperties;
}

const brandImgCarouselListMo: ICarousel[] = [
    {
        title: '선릉역 10번출구 도보 1분 거리로\n편하게 방문하실 수 있습니다.',
        imageUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-1/mo-section-1-img-card-0.png',
    },
    {
        title: '150평 초대형 규모로\n쾌적한 요가를 즐기실 수 있습니다.',
        imageUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-1/mo-section-1-img-card-1.png',
    },
    {
        title: '22년 경력 요가 마스터와\n나이키, 룰루레몬 앰버서더 코치진이\n20가지 프로그램을 진행합니다.',
        imageUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-1/mo-section-1-img-card-2.png',
    },
    {
        title: '유러피안 오크 원목 바닥은\n호흡에 몰입을 도와줍니다.',
        imageUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-1/mo-section-1-img-card-3.png',
    },
    {
        title: '룰루레몬 매트, 소도구가 완비되어\n자유롭게 대여할 수 있습니다.',
        imageUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-1/mo-section-1-img-card-4.png',
    },
    {
        title: '요가 전후 지중해식 기반 키친에서\n건강한 음식으로 에너지를 채워보세요.',
        imageUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-1/mo-section-1-img-card-5.png',
    }
]

const squareImgCardListMo: ISquareImgCard[] = [
    {
        title: '요가',
        imgUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-2/mo-section-2-square-img-card-0.png',
    },
    {
        title: '코어',
        imgUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-2/mo-section-2-square-img-card-1.png',
    },
    {
        title: '필라테스',
        imgUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-2/mo-section-2-square-img-card-2.png',
    },
    {
        title: '명상',
        imgUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-2/mo-section-2-square-img-card-3.png',
    },
]

const brandTextCardListMo: IBrandTextCardMo[] = [
    {
        mainContent: '"직장인에게 위치적으로, \n시설적으로 가장 적합한 공간입니다."',
        subContent: '이시*',
    },
    {
        mainContent: '“고급진 시설과 안락한 분위기, \n체계적인 프로그램을 지원해요.”',
        subContent: 'sal****',
    },
    {
        mainContent: '“회사 점심시간을 이용하는데\n마음 안정과 두뇌 활동에 긍정적입니다.”',
        subContent: 'let***',
    },
    {
        mainContent: '“샤워 시설도 깔끔하고 매트 등 \n도구도 대여 가능해서 편리합니다.”',
        subContent: 'tae***',
    },
    {
        mainContent: '“시설, 푸드, 코치님의 실력까지\n더할나위 없이 좋은 경험입니다.”',
        subContent: '브리다**',
    },
    {
        mainContent: '“초보자도 잘 따라갈 수 있게 \n친절하게 지도해주셔서 만족합니다.”',
        subContent: 'hyn***',
    },
    {
        mainContent: '"직장인에게 위치적으로, \n시설적으로 가장 적합한 공간입니다."',
        subContent: '이시*',
    },
]

const brandCenterCardListMo: IBrandCenterCardMo[] = [
    {
        imageUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-2/mo-section-2-center-card-0.png',
        title: '선릉',
        description: '150평 규모의 복합 웰니스 스튜디오\n서울 강남구 테헤란로 407, 1F',
        style: { paddingTop: '62.5%' }
    },
    {
        imageUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-2/mo-section-2-center-card-1.png',
        title: '도산',
        description: '깊은 몰입을 위한 조명과 사운드 시스템을 갖춘 스튜디오\n서울 강남구 압구정로 46길 77',
        style: { paddingTop: '62.5%' }
    },
    {
        imageUrl: 'https://img.positivehotel.io/2024/12/24/brand/mo/section-2/mo-section-2-center-card-2png.png',
        title: '강남',
        description: '클래식 요가에 최적화된 아틀리에 스튜디오\n서울 강남구 선릉로 131길 4, 3·4F',
        style: { paddingTop: '62.5%' }
    }
]

export { brandImgCarouselListMo, brandTextCardListMo, squareImgCardListMo, brandCenterCardListMo }