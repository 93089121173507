import { useNavigate } from "react-router-dom";
import { ReactComponent as BrandPositivehotelyogaLogo } from "../../../../../../assets/images/brandPositivehotelyogaLogo.svg"
import { BrandDetailBtnMo } from "../../../../../../shared/button/brandBtn/mo";
import { brandCenterCardListMo, brandImgCarouselListMo, brandTextCardListMo, squareImgCardListMo } from "../model";
import { SquareImgCardMo } from "../../../../../../shared/card/squareImgCardMo";
import { InfiniteScrollCarousel } from "../../../../../../shared/card/brandTextCardMo";
import { ImgCarousel } from "../imgCarousel";
import { CenterImgCarousel } from "../centerImgCarousel";
import { DividerThin } from "../../../../../../shared";
import './index.css'

const BrandMoBodyV2 = () => {
    const navigate = useNavigate();

    return <>
        <div className='brand-mo-body-v2-wrapper'>
            <div className="brand-mo-body-v2-container">
                <div className='brand-mo-body-v2-top-banner-container' onClick={() => navigate('/event/13')}>
                    <div className="brand-mo-body-v2-top-banner-text title/11">
                        신규 회원 최대 50% 할인 이벤트
                    </div>
                </div>
                <div className='brand-mo-body-v2-section-0-container'>
                    <BrandPositivehotelyogaLogo className='brand-mo-body-v2-section-0-logo' width={218} height={20} />
                    <div className='brand-mo-body-v2-section-0-description body/b1'>
                        파지티브호텔 요가는 건강한 음식과 요가 프로그램이 결합된<br />
                        새로운 형태의 웰니스 프로그램을 제안합니다.<br />
                        전문 웰니스 코치들이 큐레이션 하는 다양한 <br />
                        요가 클래스와 건강하고 긍정적인 삶의 가치를 <br />
                        공유하는 커뮤니티를 통해 <br />
                        움직임을 넘어, 몸과 마음의 변화를 경험해 보세요. <br />
                    </div>
                </div>

                <div className='brand-mo-body-v2-section-0_1-container'>
                    <div className='brand-mo-body-v2-section-0_1-title'>
                        <div className='brand-mo-body-v2-section-0_1-title-0 sub-title/st4 primary/p900'>
                            최대 50% 할인
                        </div>
                        <div className='brand-mo-body-v2-section-0_1-title-1 title/t4 secondary/s850'>
                            신규 회원 혜택
                        </div>
                        <div className='brand-mo-body-v2-section-0_1-title-2 body/b2 secondary/s500'>
                            2025.2.28 까지
                        </div>
                    </div>
                    <div className='brand-mo-body-v2-section-0_1-description-container'>
                        <DividerThin style={{ backgroundColor: 'var(--secondarys300)' }} />
                        <div className='brand-mo-body-v2-section-0_1-description'>
                            <div className="brand-mo-body-v2-section-0_1-description-0-container">
                                <div className="brand-mo-body-v2-section-0_1-description-0-0">
                                    <div className="brand-mo-body-v2-section-0_1-description-0-0-0">
                                        ALL PASS
                                    </div>
                                    <div className="brand-mo-body-v2-section-0_1-description-0-0-1 title/t2">
                                        1회권
                                    </div>
                                </div>
                                <div className="brand-mo-body-v2-section-0_1-description-0-1-container">
                                    <div className="brand-mo-body-v2-section-0_1-description-0-1-0 body/b2 secondary/s500">
                                        50,000원
                                    </div>
                                    <div className="brand-mo-body-v2-section-0_1-description-0-1-1 title/t2 primary/p900">
                                        30%
                                    </div>
                                    <div className="brand-mo-body-v2-section-0_1-description-0-1-2 title/t2 secondary/s850">
                                        35,000원
                                    </div>
                                </div>
                            </div>
                            <DividerThin style={{ backgroundColor: 'var(--secondarys300)' }} />
                            <div className="brand-mo-body-v2-section-0_1-description-1-container">
                                <div className="brand-mo-body-v2-section-0_1-description-1-0">
                                    ALL PASS<br />
                                    4회권
                                </div>
                                <div className="brand-mo-body-v2-section-0_1-description-1-1-container">
                                    <div className="brand-mo-body-v2-section-0_1-description-1-1-0-container">
                                        <div className="brand-mo-body-v2-section-0_1-description-1-1-0-0 body/b2 secondary/s500">
                                            190,000원
                                        </div>
                                        <div className="brand-mo-body-v2-section-0_1-description-1-1-0-1 title/t2 primary/p900">
                                            50%
                                        </div>
                                        <div className="brand-mo-body-v2-section-0_1-description-1-1-0-2 title/t2 secondary/s850">
                                            95,000원
                                        </div>
                                    </div>
                                    <div className="brand-mo-body-v2-section-0_1-description-1-1-1 sub-title/st3 primary/p850">
                                        1회 23,750원
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DividerThin style={{ backgroundColor: 'var(--secondarys300)' }} />
                    </div>
                    <div className="brand-mo-body-v2-section-0_1-btn-container">
                        <div className="brand-mo-body-v2-section-0_1-btn-description sub-title/st2 secondary/s600">
                            회원가입 시 쿠폰 자동 발급
                        </div>
                        <BrandDetailBtnMo classNames={{ container: 'brand-mo-body-v2-section-0_1-detail-btn', text: 'sub-title/st3' }} content={'회원가입하기'} onClick={() => navigate('/login')} />
                    </div>
                </div>

                <div className='brand-mo-body-v2-section-1-container'>
                    <div className="brand-mo-body-v2-section-1-title title/t4">
                        요가와 키친이 결합된<br />
                        도심 속 홀리스틱 웰니스 공간<br />
                    </div>
                    <div className='brand-mo-body-v2-section-1-img-carousel-container' >
                        <ImgCarousel carouselList={brandImgCarouselListMo} />
                    </div>
                </div>

                <div className='brand-mo-body-v2-section-2-container'>
                    <div className='brand-mo-body-v2-section-2-coach-container' >
                        <div className='brand-mo-body-v2-section-2-coach-title title/t4' >
                            국내 정상급 코치진이<br />
                            큐레이션하는 웰니스 프로그램<br />
                        </div>
                        <div className="brand-mo-body-v2-section-2-coach-square-img-card-mo-container">
                            {squareImgCardListMo.map((q, idx) => <SquareImgCardMo key={idx} title={q.title} imgUrl={q.imgUrl} />)}
                        </div>
                    </div>
                    <div className="brand-mo-body-v2-section-2-experience-container">
                        <div className="brand-mo-body-v2-section-2-experience-text sub-title/st2">
                            신규 회원 최대 50% 할인
                        </div>
                        <BrandDetailBtnMo content={'시간표 보기'} onClick={() => navigate('/yoga-center/3#part-3')} />
                    </div>
                    <div className="brand-mo-body-v2-section-2-text-card-container">
                        <InfiniteScrollCarousel brandTextCardListMo={brandTextCardListMo} />
                    </div>
                    <div className="brand-mo-body-v2-section-2-wellness-ticket-intro-container">
                        <div className="brand-mo-body-v2-section-2-wellness-ticket-intro-title sub-title/st4">
                            ALL PASS 정기권으로
                        </div>
                        <div className="brand-mo-body-v2-section-2-wellness-ticket-intro-description title/t4">
                            선릉・강남・도산 3개 지점을<br />
                            자유롭게 이용해보세요<br />
                        </div>
                    </div>

                    <div className="brand-mo-body-v2-section-2-wellenss-ticket-purchase-container">
                        <div className="brand-mo-body-v2-section-2-wellenss-ticket-purchase-text sub-title/st2">
                            신규 회원 50% 할인 혜택
                        </div>
                        <BrandDetailBtnMo content={'정기권 구매하기'} onClick={() => navigate('/wellness-ticket-group/1')} />
                    </div>
                    <div className="brand-mo-body-v2-section-2-center-card-container">
                        <CenterImgCarousel carouselList={brandCenterCardListMo} />
                    </div>
                </div>

                <div className="brand-mo-body-v2-section-3-container">
                    <div className='brand-mo-body-v2-section-3-content-container'>
                        <div className='brand-mo-body-v2-section-3-sub-content sub-title/st4'>
                            선릉・강남・도산 어디서든
                        </div>
                        <div className='brand-mo-body-v2-section-3-main-content title/t4'>
                            수강권 결제부터 수업 예약까지<br />
                            간편하게 시작해보세요<br />
                        </div>
                    </div>
                    <BrandDetailBtnMo classNames={{ container: 'brand-mo-body-v2-section-3-detail-btn' }} content={'앱 둘러보기'} onClick={() => navigate('https://positivehotel.page.link/sBAV')} />
                    <div className='brand-mo-body-v2-section-3-reservation-intro-img'>

                    </div>
                </div>
            </div>
        </div >
    </>
}

export { BrandMoBodyV2 };