import { useNavigate } from 'react-router-dom';
import { ReactComponent as BrandPositivehotelyogaLogo } from "../../../../../assets/images/brandPositivehotelyogaLogo.svg"
import { TTargetName } from '../model';
import './index.css';

interface IProps {
    className?: string;
    handleScrollTo: (target: TTargetName) => void;
}

const BrandPcHeader = ({ className, handleScrollTo }: IProps) => {
    const navigate = useNavigate();

    return <div className={`brand-pc-header-wrapper ${className}`}>
        <div className='brand-pc-header-container'>
            <BrandPositivehotelyogaLogo className='brand-pc-header-logo' width={305} height={28} fill='#000101' onClick={() => navigate('/brand')} />
            <div className='nav-container'>
                <div className='about-btn' onClick={() => handleScrollTo('about')}>
                    <div className='text title/t3'>
                        About
                    </div>
                </div>
                <div className='place-btn' onClick={() => handleScrollTo('place')}>
                    <div className='text title/t3'>
                        공간 소개
                    </div>
                </div>
                <div className='program-btn' onClick={() => handleScrollTo('program')}>
                    <div className='title/t3'>
                        프로그램
                    </div>
                </div>
                <div className='new-member-event-btn' onClick={() => navigate('/event/11')}>
                    <div className='text sub-title/st4'>
                        신규회원 이벤트
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export { BrandPcHeader };