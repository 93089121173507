import { ReactComponent as BrandArrowRight } from "../../../../assets/images/icon/brandArrowRight.svg"
import './index.css';

interface IProps {
    content: string;
    icon?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    classNames?: { container?: string, text?: string, icon?: string };
    styles?: { container?: React.CSSProperties, text?: React.CSSProperties, icon?: React.CSSProperties };
}

const BrandDetailBtnMoUI = ({ content, icon, onClick, classNames, styles }: IProps) => (
    <div className={`detail-btn-mo-container ${classNames?.container}`} style={styles?.container} onClick={onClick}>
        <div className={`detail-btn-mo-text sub-title/st3 ${classNames?.text}`} style={styles?.text}>{content}</div>
        {icon ?? <BrandArrowRight className={`detail-btn-mo-icon ${classNames?.icon}`} style={styles?.icon} width={16} height={16} />}
    </div>)

const BrandDetailBtnMo = ({ content, icon, onClick, classNames, styles }: IProps) => {
    return <>
        <BrandDetailBtnMoUI content={content} icon={icon} onClick={onClick} classNames={classNames} styles={styles} />
    </>
}


export { BrandDetailBtnMo }