import { IsMobileDevice } from "../../../utils";
import { BrandMo } from "./mo";
import { BrandPc } from "./pc";

const Brand = () => {
    return <>
        {IsMobileDevice()
            ? <BrandMo />
            : <BrandPc />}
    </>
}

export { Brand };