import './index.css';

interface IProps {
    title: string;
    imgUrl: string;
}

const SquareImgCardMoUI = ({ title, imgUrl }: IProps) => (
    <div className="square-img-card-mo-wrapper">
        <div className="square-img-card-mo-container">
            <img className="square-img-card-mo-img" src={imgUrl} alt="square-img" width={'101%'} height={'100%'} />
            <div className="square-img-card-mo-title">
                {title}
            </div>
        </div>
    </div>)

const SquareImgCardMo = ({ title, imgUrl }: IProps) => {

    return <SquareImgCardMoUI
        title={title}
        imgUrl={imgUrl} />
}

export { SquareImgCardMo };