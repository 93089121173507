export interface IYogaCenter {
    id: number
    name: string
    shortName: string
    description: string
    image: string
}

const yogaCenterDataList = [{
    id: 1,
    name: '파지티브호텔 요가 강남',
    shortName: '강남',
    description: '인스트럭터 양성을 위한 심도 있는 \n요가 클래스를 만나보세요.',
    image: 'https://img.positivehotel.io/2024/12/17/gang_01.webp'
}, {
    id: 2,
    name: '파지티브호텔 요가 도산',
    shortName: '도산',
    description: '다양한 요가 프로그램을 소수 인원의 \n프라이빗 클래스로 만나보세요.',
    image: 'https://img.positivehotel.io/2024/12/17/dosan_01.webp'
}, {
    id: 3,
    name: '파지티브호텔 요가 선릉',
    shortName: '선릉',
    description: '요가 프로그램과 웰니스 푸드를 결합한 \n복합 웰니스 솔루션을 만나보세요.',
    image: 'https://img.positivehotel.io/2024/12/17/seoll_01.webp'
}]

export { yogaCenterDataList }