import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const BrandLayout = () => {
    return <>
        <Helmet>
            <meta name="robots" content="index, follow" />
            <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
            <title>파지티브호텔요가</title>
            <meta name="description" content="도심 속 홀리스틱 웰니스공간을 만나보세요." />
        </Helmet>
        <Outlet />
    </>
};

export default BrandLayout;