import React, { useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { Calendar, Flex } from 'antd';
import type { CalendarProps } from 'antd';
import type { Dayjs } from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import { createStyles } from 'antd-style';
import classNames from 'classnames';
import { ReactComponent as Ellipse } from "../../../../assets/images/ellipse.svg"
import { ReactComponent as ArrowLeft } from "../../../../assets/images/icon/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/images/icon/arrow-right.svg";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../modules';
import { ThunkDispatch } from 'redux-thunk';
import { getSelectedYogaCenteAndDaterAsync } from '../../../../modules/selectedYogaCenterAndDate';

dayjs.extend(dayLocaleData);

interface IProps {
  reservedDateList?: Array<string>
}

const useStyle = createStyles(({ token, css, cx }) => {
  const weekend = css`
      color: ${token.colorError};
      &.gray {
        opacity: 0.4;
      }
    `;
  return {
    wrapper: css`
        width: 100%;
      `,
    dateCell: css`
        position: relative;
        &:before {
          content: '';
          position: absolute;
          inset-inline-start: 0;
          inset-inline-end: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          max-width: 40px;
          max-height: 40px;
          background: transparent;
          transition: background-color 300ms;
          border-radius: ${token.borderRadiusOuter}px;
          border: 1px solid transparent;
          box-sizing: border-box;
        }
        &:hover:before {
          background: rgba(0, 0, 0, 0.04);
        }
      `,
    today: css`
        &:before {
          border: 0.75px solid var(--secondarys500);
          // background: #fff96982;
        }
      `,
    text: css`
        position: relative;
        z-index: 1;
        padding-top: 4px;
      `,
    current: css`
        color: #000000;
        &:before {
          background: #fff969;
        }
        &:hover:before {
          background: #fff969;
          opacity: 0.8;
        }
        .${cx(weekend)} {
          color: #000000;
        }
      `,
    monthCell: css`
        width: 120px;
        color: ${token.colorTextBase};
        border-radius: ${token.borderRadiusOuter}px;
        padding: 5px 0;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      `,
    monthCellCurrent: css`
        color: ${token.colorTextLightSolid};
        background: ${token.colorPrimary};
        &:hover {
          background: ${token.colorPrimary};
          opacity: 0.8;
        }
      `,
    weekend,
  };
});


const CalendarReservation = ({ reservedDateList }: IProps) => {
  const update = getSelectedYogaCenteAndDaterAsync;
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { yogaCenterId, date } = useSelector((state: RootState) => state.selectedYogaCenterAndDate);
  const [selectedDate, setSelectedDate] = useState<string>(date);

  const { styles } = useStyle({ test: true });

  const [panelDateDate, setPanelDate] = React.useState<Dayjs>(dayjs());

  const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>['mode']) => {
    setPanelDate(value);
    dispatch(update({ yogaCenterId: yogaCenterId, date: value.format('YYYY-MM-DD') }));
    setSelectedDate(value.format('YYYY-MM-DD'));
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (date, info) => {
    const isWeekend = date.day() === 6 || date.day() === 0;
    if (info.type === 'date') {
      return React.cloneElement(info.originNode, {
        ...info.originNode.props,
        className: classNames(styles.dateCell, {
          [styles.current]: dayjs(selectedDate).isSame(date, 'date'),
          [styles.today]: date.isSame(dayjs(), 'date'),
        }),
        children: (
          <div className={styles.text}>
            <span
              className={classNames({
                [styles.weekend]: isWeekend,
                gray: !panelDateDate.isSame(date, 'month'),
              })}
            >
              {date.get('date')}
            </span>

            {reservedDateList?.includes(date.format("YYYY-MM-DD"))
              ? <div style={{ lineHeight: '0px', paddingBottom: 4 }}>
                <Ellipse fill={selectedDate === date.format('YYYY-MM-DD') ? '#000000' : '#D9D9D9'} />
              </div>
              : <div style={{ height: 4 }}></div>}

          </div>
        ),
      });
    }

    return info.originNode;
  };

  const onDateChange: CalendarProps<Dayjs>['onSelect'] = (value, selectInfo) => {
    if (selectInfo.source === 'date') {
      dispatch(update({ yogaCenterId: yogaCenterId, date: value.format('YYYY-MM-DD') }));
      setSelectedDate(value.format('YYYY-MM-DD'));
    }
  };

  return <>
    <div className={styles.wrapper}>
      <Calendar
        locale={{ ...locale, lang: { ...locale.lang, shortWeekDays: ["일", "월", "화", "수", "목", "금", "토"] } }}
        fullscreen={false}
        headerRender={({ value, onChange }) => {
          return <>
            <div style={{ padding: 8 }}>
              <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <ArrowLeft width={24} height={24} stroke={value.endOf("month") >= dayjs().add(-2, 'M').endOf("month") ? "black" : '#cdcdcd'}
                  style={{ cursor: "pointer", padding: 'var(--space12)', marginInline: 'var(--space16)', pointerEvents: value.endOf("month") >= dayjs().add(-2, 'M').endOf("month") ? 'auto' : 'none' }}
                  onClick={() => value.endOf("month") >= dayjs().add(-2, 'M').endOf("month") && onChange(value.clone().add(-1, 'month'))} />

                <div style={{ fontSize: 20, fontWeight: 600 }}>{`${value.year()}년 ${value.month() + 1}월`}</div>

                <ArrowRight width={24} height={24} stroke={value.endOf("month") <= dayjs().endOf("month") ? "black" : '#cdcdcd'}
                  style={{ cursor: "pointer", padding: 'var(--space12)', marginInline: 'var(--space16)', pointerEvents: value.endOf("month") <= dayjs().endOf("month") ? 'auto' : 'none' }}
                  onClick={() => value.endOf("month") <= dayjs().endOf("month") && onChange(value.clone().add(+1, 'month'))} />
              </Flex>
            </div>
          </>
        }}
        fullCellRender={cellRender}
        onPanelChange={onPanelChange}
        onSelect={onDateChange}
      />
    </div>
  </>
}

export default CalendarReservation