import { useEffect, useRef } from 'react';
import './index.css';

interface IProps {
    mainContent: string;
    subContent: string;
    styles?: { wrapper?: React.CSSProperties, container?: React.CSSProperties, main?: React.CSSProperties, sub?: React.CSSProperties }
}

const BrandTextCardMoUi = ({ mainContent, subContent, styles }: IProps) => (
    <div className="brand-text-card-mo-wrapper" style={styles?.wrapper} >
        <div className="brand-text-card-mo-container" style={styles?.container} >
            <div className="brand-text-card-mo-content-container">
                <div className="brand-text-card-mo-main-content body/b3" style={styles?.main} >{mainContent}</div>
                <div className="brand-text-card-mo-sub-content caption/c1" style={styles?.sub} >{subContent}</div>
            </div>
        </div>
    </div>
)

const BrandTextCardMo = ({ mainContent, subContent, styles }: IProps) => {
    return <BrandTextCardMoUi
        mainContent={mainContent}
        subContent={subContent}
        styles={styles} />
}

interface ICarouselProps {
    brandTextCardListMo: { mainContent: string, subContent: string }[]
}


const InfiniteScrollCarousel = ({ brandTextCardListMo }: ICarouselProps) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
            const scrollWidth = container.scrollWidth - container.clientWidth;
            let scrollPosition = 0;

            const interval = setInterval(() => {
                if (container) {
                    container.scrollLeft = scrollPosition;
                    scrollPosition += 0.5;
                    if (scrollPosition > scrollWidth) {
                        scrollPosition = 0;
                    }
                }
            }, 10);
            return () => clearInterval(interval);
        }
    }, [brandTextCardListMo]);


    return <>
        <div className="infinite-scroll-carousel-container" ref={scrollContainerRef}>
            {brandTextCardListMo.map((q, idx) => <BrandTextCardMo key={idx} mainContent={q.mainContent} subContent={q.subContent} />)}
        </div>
    </>
}

export { BrandTextCardMo, InfiniteScrollCarousel };