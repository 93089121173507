import { Modal } from "antd";
import { ReactComponent as PlusCircle } from "../../../assets/images/icon/plusCircle.svg"
import { ReactComponent as Close } from "../../../assets/images/icon/close.svg"
import { useState } from "react";
import './index.css';

interface IProps {
    title: string;
    thumbnailImgUrl: string;
    popupImgUrl: string;
    popupDescription: string;
    style?: React.CSSProperties;
    isShow?: boolean;
}

interface IUIProps extends IProps {
    isModalStatus: boolean;
    setIsModalStatus: (status: boolean) => void;
}

const BrandImgCardUI = ({ title, thumbnailImgUrl, popupImgUrl, popupDescription, style, isModalStatus, setIsModalStatus, isShow }: IUIProps) => (<>
    <div className="brand-card-wrapper">
        <div className="brand-card-container" style={{ ...style, backgroundImage: `url(${thumbnailImgUrl})` }} onClick={() => setIsModalStatus(true)}>
            <div className="brand-card-title">
                <div className="brand-card-title-text">
                    {title}
                </div>
            </div>
            <PlusCircle className="brand-card-icon" width={35} height={35} />
        </div>
    </div>
    <Modal className="brand-card-modal" centered width={820} open={isModalStatus} onCancel={() => setIsModalStatus(false)} footer={null} closeIcon={false}>
        <Close className="brand-card-modal-close-icon" width={41.5} height={41.5} onClick={() => setIsModalStatus(false)} />
        <img className="brand-card-modal-img" width={820} height={820} src={popupImgUrl} alt="brand-img" />
        <div className={`brand-card-modal-description ${isShow ? ' isShow ' : ' '}`}>
            {popupDescription}
        </div>
        {isShow &&
            <div style={{ position: 'absolute', bottom: '40px', left: '50px', whiteSpace: 'pre', fontSize: '24px', fontWeight: '500', lineHeight: '28.8px', textAlign: 'left', color: ' var(--basewhite)', }}>
                ✢ 런치클래스 스몰밀 제공
            </div>}
    </Modal>
</>)

const BrandImgCard = ({ title, thumbnailImgUrl, popupImgUrl, popupDescription, style, isShow }: IProps) => {
    const [isModalStatus, setIsModalStatus] = useState<boolean>(false);

    return <BrandImgCardUI
        title={title}
        thumbnailImgUrl={thumbnailImgUrl}
        popupImgUrl={popupImgUrl}
        popupDescription={popupDescription}
        style={style}
        isModalStatus={isModalStatus}
        setIsModalStatus={setIsModalStatus}
        isShow={isShow} />
}

export { BrandImgCard }