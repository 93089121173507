import dayjs from "dayjs";
import { BrandMoBodyV1 } from "./v1";
import { BrandMoBodyV2 } from "./v2";

const BrandMoBody = () => {
    const now = dayjs();
    const targetTime = dayjs('2025-01-20T00:00:00+09:00')

    return <>
        {now.isBefore(targetTime)
            ? <BrandMoBodyV1 />
            : <BrandMoBodyV2 />}
    </>
}

export { BrandMoBody }