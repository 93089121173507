import { useEffect, useState } from "react";
import MySkeleton from "../../components/skeleton/my/mySkeleton";
import { MyBeforeLogin } from "./ui/myBeforeLogin";
import { TopBar } from "../../widgets";
import { IssuedWellnessTicketCarousel, ReservatedProgramList } from "../../features";
import { GnbFooter, MemberNameAndTitle, MemberBenefitInfo, BasicInfoRow } from "../../shared";
import { removeUser, getPremiumMembership, getDosanProgramMember, getMemberByMemberId, getAllReservationByMemberIdAndReservationStatus } from "../../service";
import { isLogin, moneyStringFormatByNumber, isPlatformTeamMember } from "../../utils";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { getAllIssuedWellnessTicketByMemberId } from "../../service/issuedWellnessTicket";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const fetchIssuedWellnessTicket = () => getAllIssuedWellnessTicketByMemberId().then(res => res.data);
const fetchReservationList = () => getAllReservationByMemberIdAndReservationStatus('RESERVATION').then(res => res.data);
const fetchMemberData = () => getMemberByMemberId().then(res => res.data);
const fetchDosanProgramMember = () => getDosanProgramMember().then(res => res.data);
const fetchPremiumMembership = () => getPremiumMembership().then(res => res.data);

const My = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    let isInitLoading = false;

    const { data: myWellnessTicketList, isLoading: isLoadingTickets, isError: isErrorTickets, error: errorTickets } = useQuery({ queryKey: ['issuedWellnessTicket'], queryFn: fetchIssuedWellnessTicket, select: (res) => res, enabled: isLogin() });
    const { data: myReservationList, isLoading: isLoadingReservations, isError: isErrorReservations, error: errorReservations } = useQuery({ queryKey: ['reservationList'], queryFn: fetchReservationList, enabled: isLogin() });
    const { data: member, isLoading: isLoadingMember, isError: isErrorMember, error: errorMember } = useQuery({ queryKey: ['member'], queryFn: fetchMemberData, enabled: isLogin() });
    const { data: dosanProgramMember, isLoading: isLoadingDosan, isError: isErrorDosan, error: errorDosan } = useQuery({ queryKey: ['dosanProgramMember'], queryFn: fetchDosanProgramMember, enabled: isLogin() });
    const { data: premiumMembership, isLoading: isLoadingMembership, isError: isErrorMembership, error: errorMembership } = useQuery({ queryKey: ['premiumMembership'], queryFn: fetchPremiumMembership, enabled: isLogin() });

    const requestIssuedWellnessTicketByMemberId = async () => {
        queryClient.invalidateQueries({ queryKey: ['issuedWellnessTicket'] })
    }

    const requestReservationByMemberId = async () => {
        queryClient.invalidateQueries({ queryKey: ['reservationList'] })
    }

    if (isLogin()) {
        isInitLoading = isLoadingMember || isLoadingTickets || isLoadingReservations || isLoadingDosan || isLoadingMembership;

        if (isInitLoading) {
            return <>
                <MySkeleton />
                <GnbFooter activeButton='my' />
            </>
        }

        if (isErrorMember || isErrorTickets || isErrorReservations || isErrorDosan || isErrorMembership) {
            console.error('Error fetching data:', errorMember, errorTickets, errorReservations, errorDosan, errorMembership);
            removeUser();
        }
    } else {
        isInitLoading = false
        return <>
            <TopBar title="MY" leftIcon={false} rightIcon={false} />
            <MyBeforeLogin />
            <GnbFooter activeButton='my' />
        </>
    }

    return <>
        {isInitLoading && <MySkeleton />}
        <div style={{ marginBottom: '52px' }}>
            <>
                {member && <>
                    <TopBar title="MY" leftIcon={false} rightType='setting' />
                    <div style={{ marginInline: 'var(--space20)', marginTop: 43 }}>
                        <MemberNameAndTitle
                            name={member.name}
                            title={member.isManager ? "매니저님" : member.isTeacher ? '코치님' : '회원님'} />
                        <div style={{ display: 'flex', gap: 'var(--space16)', marginBlock: '24px' }}>
                            <div style={{ width: '50%' }}>
                                <MemberBenefitInfo title="멤버십" value={premiumMembership ? premiumMembership.membershipTitle : member.grade} to={`/member-ship?grade=${member.grade === "STANDARD" ? 0 : member.grade === "DELUXE" ? 1 : 2}`} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <MemberBenefitInfo title="포인트 · 쿠폰" value={moneyStringFormatByNumber(member.totalPoint) + "p"} to="/my/reward" />
                            </div>
                        </div>
                    </div>

                    {myWellnessTicketList && <IssuedWellnessTicketCarousel myIssuedWellnessTicketList={myWellnessTicketList} />}
                    {myReservationList && <ReservatedProgramList dosanProgramMember={dosanProgramMember} requestMyReservationByToken={requestReservationByMemberId} requestMyTicketByToken={requestIssuedWellnessTicketByMemberId} myReservationList={myReservationList} memberData={member} />}

                    <div style={{ padding: 'var(--space16) var(--space20) 0' }}>
                        {member.isTeacher && <>
                            <BasicInfoRow title={member.isManager ? "모든 수업 확인" : "내 수업 확인"} onClick={() => navigate(`/mylecture/teacher/${member.teacherId}`)} />
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        </>}
                        <BasicInfoRow title={<>수련 내역 & 리뷰</>} onClick={() => navigate('/my/training-history')} />
                        <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        <BasicInfoRow title={"결제 내역"} onClick={() => navigate("/my/paid-history/" + member.id)} />
                        <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        <BasicInfoRow title={"정기권 구매"} onClick={() => navigate("/wellness-ticket-group")} />
                        {member.isManager && <>
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                            <BasicInfoRow title={"이벤트 (관리자용)"} onClick={() => navigate("/event")} />
                        </>}
                        {isPlatformTeamMember(member.mobile) && <>
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                            <BasicInfoRow
                                title={window.location.origin === "https://dev.positivehotelyoga.com" ? "운영용 페이지로 이동" : "개발용 페이지로 이동"}
                                onClick={() => window.location.href = window.location.origin === "https://dev.positivehotelyoga.com" ? "https://positivehotelyoga.com" : "https://dev.positivehotelyoga.com"} />
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        </>}
                    </div>
                </>}
            </>
        </div>
        <GnbFooter activeButton='my' />
    </>
}

export { My }