import { ReactComponent as BrandArrowRight } from "../../../assets/images/icon/brandArrowRight.svg"
import './index.css'

interface IProps {
    imgUrl: string;
    title: string;
    description: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const BrandCenterCardUi = ({ imgUrl, title, description, onClick }: IProps) => (
    <div className='brad-center-card-wrapper'>
        <div className='brad-center-card-container'>
            <div className='brad-center-card-img-container' onClick={onClick} >
                <img className={`brad-center-card-img`} width={400} height={300} src={imgUrl} alt="center-card-img" />
                <div className={`brad-center-card-img-overlay ${onClick && 'brad-center-card-img-overlay-pointer'}`}>
                    <div className='brad-center-card-img-overlay-text title/t3'>
                        자세히 보기
                    </div>
                    <BrandArrowRight className="brad-center-card-img-overlay-icon" width={24} height={24} />
                </div>
            </div>

            <div className='brad-center-card-content-container'>
                <div className='brad-center-card-title-title title/t3'>{title}</div>
                <div className='brad-center-card-title-description'>{description}</div>
            </div>
        </div>
    </div>
)

const BrandCenterCard = ({ imgUrl, title, description, onClick }: IProps) => {
    return <>
        <BrandCenterCardUi imgUrl={imgUrl} title={title} description={description} onClick={onClick} />
    </>
}


export { BrandCenterCard };