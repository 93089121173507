import { useNavigate } from "react-router-dom";
import { ReactComponent as BrandPositivehotelyogaLogo } from "../../../../../assets/images/brandPositivehotelyogaLogo.svg"
import { ReactComponent as ChIcon } from "../../../../../assets/images/icon/chIcon.svg";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { TTargetName } from "../model";
import { BrandImgCard } from "../../../../../shared/card/brandImgCard";
import { brandCenterCardList, brandImgCardList, brandTextCardList, squareImgCardList } from "./model";
import { BrandTextCard } from "../../../../../shared/card/brandTextCard";
import { SquareImgCardPc } from "../../../../../shared/card/squareImgCardPc";
import { BrandDetailBtnPc } from "../../../../../shared/button/brandBtn/pc";
import { BrandCenterCard } from "../../../../../shared/card/brandCenterCard";
import './index.css';

interface IProps {
    scrollToDiv: (target: TTargetName) => void;
}

const BrandPcBody = forwardRef<IProps>((_, ref) => {
    const navigate = useNavigate();
    const divRefs = useRef<HTMLDivElement[]>([]);

    useImperativeHandle(ref, () => ({
        scrollToDiv: (target: TTargetName) => {
            const targetNameList: TTargetName[] = ['about', 'place', 'program']
            const index = targetNameList.indexOf(target);
            if (divRefs.current[index]) {
                divRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start', });
            }
        },
    }));

    return <>
        <div className="brand-pc-body-wrapper">
            <div className='brand-pc-body-container'>
                <div className="brand-pc-body-section-0" ref={e => e && (divRefs.current[0] = e)}>
                    <div className="brand-pc-body-section-0-bg-container" >
                        <BrandPositivehotelyogaLogo className='brand-pc-body-section-0-logo' width={413.5} height={38} fill='var(--basewhite)' />
                        <div className='brand-pc-body-section-0-description'>
                            파지티브호텔 요가는 건강한 음식과 요가 프로그램이 결합된 새로운 형태의 웰니스 프로그램을 제안합니다.<br />
                            전문 웰니스 코치들이 큐레이션 하는 다양한 요가 클래스와 건강하고 긍정적인 삶의 가치를 공유하는 커뮤니티를 통해<br />
                            움직임을 넘어, 몸과 마음의 변화를 경험해 보세요.<br />
                        </div>
                        <BrandDetailBtnPc classNames={{ container: 'brand-pc-body-section-0-detail-btn' }} content={'스튜디오 자세히'} onClick={() => navigate('/yoga-center/3')} />
                    </div>
                </div>

                <div className="brand-pc-body-section-1" ref={e => e && (divRefs.current[1] = e)}>
                    <div className="brand-pc-body-section-1-title">
                        요가와 키친이 결합된<br />
                        도심 속 홀리스틱 웰니스 공간<br />
                    </div>
                    <div className="brand-pc-body-section-1-img-card-container">
                        {brandImgCardList.map((q, idx) => <BrandImgCard key={idx} title={q.title} thumbnailImgUrl={q.thumbnailImgUrl} popupImgUrl={q.popupImgUrl} popupDescription={q.popupDescription} isShow={brandImgCardList.length - 1 === idx} />)}
                    </div>
                </div>

                <div className="brand-pc-body-section-2" ref={e => e && (divRefs.current[2] = e)}>
                    <div className="brand-pc-body-section-2-bg-container" >
                        <div className="brand-pc-body-section-2-overlay">
                            <div className="brand-pc-body-section-2-title">국내 정상급 코치진이 큐레이션하는 웰니스 프로그램</div>
                            <div className="brand-pc-body-section-2-text-card-container">
                                {brandTextCardList.map((q, idx) => <BrandTextCard key={idx} mainContent={q.mainContent} subContent={q.subContent} />)}
                            </div>
                            <div className="brand-pc-body-section-2-square-img-card-container">
                                {squareImgCardList.map((q, idx) => <SquareImgCardPc key={idx} title={q.title} imgUrl={q.imgUrl} />)}
                            </div>
                            <div className="brand-pc-body-section-2-experience-container">
                                <div className="brand-pc-body-section-2-experience-text sub-title/st4">
                                    신규 회원 50% 할인 혜택
                                </div>
                                <BrandDetailBtnPc content={'클래스 1회 체험하기'} onClick={() => navigate('/reservation?id=3')} />
                            </div>
                            <div className="brand-pc-body-section-2-wellness-ticket-intro-container">
                                <div className="brand-pc-body-section-2-wellness-ticket-intro-title">
                                    ALL PASS 정기권으로
                                </div>
                                <div className="brand-pc-body-section-2-wellness-ticket-intro-description">
                                    선릉・강남・도산 3개 지점을 자유롭게 이용해보세요
                                </div>
                            </div>
                            <div className="brand-pc-body-section-2-center-card-container">
                                {brandCenterCardList.map((q, idx) => <BrandCenterCard key={idx} imgUrl={q.imgUrl} title={q.title} description={q.description} onClick={() => q.href && navigate(q.href)} />)}
                            </div>
                            <div className="brand-pc-body-section-2-wellenss-ticket-purchase-container">
                                <div className="brand-pc-body-section-2-wellenss-ticket-purchase-text sub-title/st4">
                                    신규 회원 50% 할인 혜택
                                </div>
                                <BrandDetailBtnPc content={'정기권 구매하기'} onClick={() => navigate('/wellness-ticket-group/1')} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="brand-pc-body-section-3">
                    <div className="brand-pc-body-section-3-container">
                        <div className='brand-pc-body-section-3-top-container'>
                            <div className='brand-pc-body-section-3-left-container'>
                                <div className='brand-pc-body-section-3-content-container'>
                                    <div className='brand-pc-body-section-3-sub-content'>
                                        선릉・강남・도산 어디서든
                                    </div>
                                    <div className='brand-pc-body-section-3-main-content'>
                                        수강권 결제부터 수업 예약까지<br />
                                        간편하게 시작해보세요
                                    </div>
                                </div>
                                <BrandDetailBtnPc classNames={{ container: "brand-pc-body-section-3-tour-btn" }} content={'앱 둘러보기'} onClick={() => navigate('https://positivehotel.page.link/sBAV')} />
                            </div>
                            <div className='brand-pc-body-section-3-reservation-intro-img'>

                            </div>
                        </div>
                        <div className='brand-pc-body-section-3-inquiry-container'>
                            <div className='brand-pc-body-section-3-inquiry-description'>
                                더 궁금한 점이 있으신가요?<br />
                                카카오톡으로 문의주시면 빠르게 답변드리겠습니다
                            </div>
                            <div className="brand-pc-body-section-3-inquiry-kakao-talk" onClick={() => window.open('http://pf.kakao.com/_xmWxbYG/chat')} >
                                <ChIcon className="brand-pc-body-section-3-inquiry-link-kakao-btn" width={45.71} height={47.14} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
});

export { BrandPcBody };