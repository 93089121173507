import './index.css';

interface IProps {
    title: string;
    imgUrl: string;
}

const SquareImgCardPcUI = ({ title, imgUrl }: IProps) => (
    <div className="square-img-card-pc-wrapper">
        <div className="square-img-card-pc-container">
            <img className="square-img-card-pc-img" src={imgUrl} alt="square-img" width={'101%'} height={'100%'} />
            <div className="square-img-card-pc-title">
                {title}
            </div>
        </div>
    </div>)

const SquareImgCardPc = ({ title, imgUrl }: IProps) => {

    return <SquareImgCardPcUI
        title={title}
        imgUrl={imgUrl} />
}

export { SquareImgCardPc };