import { ReactComponent as BrandPositivehotelyogaLogo } from "../../../../../assets/images/brandPositivehotelyogaLogo.svg"
import './index.css';

const BrandPcFooter = () => {
    return <>
        <div className="brand-pc-footer-wrapper">
            <div className="brand-pc-footer-container">
                <div className="brand-pc-footer-title-container">
                    <BrandPositivehotelyogaLogo className='brand-pc-footer-title-logo' width={305.42} height={28} fill='var(--secondarys400)' />
                    <div className="brand-pc-footer-title-sns-container">
                        <div className="brand-pc-footer-title-sns-kakao" onClick={() => window.open('https://pf.kakao.com/_xmWxbYG/chat')}>
                        </div>
                        <div className="brand-pc-footer-title-sns-insta" onClick={() => window.open('https://www.instagram.com/positivehotel_yoga/')}>
                        </div>
                    </div>
                </div>
                <div className="brand-pc-footer-info-container">
                    <div className="brand-pc-footer-info-company-container">
                        <div className="brand-pc-footer-info-company-0">
                            (주)파지티브호텔 | 대표 정형록
                        </div>
                        <div className="brand-pc-footer-info-company-1">
                            사업자등록번호 231-87-00420 | 통신판매업신고번호 2016-서울강남-04088 <a className="body/b3" href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2318700420" target="_blank" rel="noreferrer noopener"  >[사업자정보확인]</a> | 개인정보보호책임자 장예지
                        </div>
                        <div className="brand-pc-footer-info-company-2">
                            서울특별시 강남구 도산대로 311 6F
                        </div>
                    </div>
                    <br />
                    <div className="brand-pc-footer-info-cs-container">
                        <div className="brand-pc-footer-info-cs-number">
                            고객센터 1522-3902
                        </div>
                        <div className="brand-pc-footer-info-cs-email">
                            이메일 positive@positivehotel.com
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export { BrandPcFooter };