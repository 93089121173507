import { ReactComponent as BrandArrowRight } from "../../../../assets/images/icon/brandArrowRight.svg"
import './index.css';

interface IProps {
    content: string;
    icon?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    classNames?: { container?: string, text?: string, icon?: string };
    styles?: { container?: React.CSSProperties, text?: React.CSSProperties, icon?: React.CSSProperties };
}

const BrandDetailBtnPcUI = ({ content, icon, onClick, classNames, styles }: IProps) => (
    <div className={`detail-btn-pc-container ${classNames?.container}`} style={styles?.container} onClick={onClick}>
        <div className={`detail-btn-pc-text title/t3 ${classNames?.text}`} style={styles?.text}>{content}</div>
        {icon ?? <BrandArrowRight className={`detail-btn-pc-icon ${classNames?.icon}`} style={styles?.icon} width={24} height={24} />}
    </div>)

const BrandDetailBtnPc = ({ content, icon, onClick, classNames, styles }: IProps) => {
    return <>
        <BrandDetailBtnPcUI content={content} icon={icon} onClick={onClick} classNames={classNames} styles={styles} />
    </>
}


export { BrandDetailBtnPc }