import { Navigate, useRoutes } from 'react-router-dom';
import BrandRoutes from './BrandRoutes';
import MainRoutes from './MainRoutes';

export default function ThemeRoutes() {
    return useRoutes([
        MainRoutes,
        BrandRoutes,
        {
            path: '/apple-app-site-association',
            element: null,
        },
        {
            path: '*',
            element: <Navigate to="/" replace />,
        },
    ]);
}
