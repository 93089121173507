import { Outlet } from 'react-router-dom';
import { SafeArea } from '../../shared';
import ScrollToTop from '../../shared/scrollToTop';
import { IsMobileDevice } from '../../utils';
import { ReactComponent as AppQrCode } from "../../assets/images/appQrCode.svg";
import CacheService from './cacheService';
import { Helmet } from 'react-helmet-async';


const MainLayout: React.FC = () => {

    return <div style={{ backgroundColor: 'var(--secondarys200)' }}>
        <SafeArea />
        <Helmet>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div style={{ maxWidth: "480px", marginInline: "auto", backgroundColor: 'var(--basewhite)', width: "auto", minHeight: "100vh" }}>
            <ScrollToTop />
            <Outlet />
        </div>
        {!IsMobileDevice()
            ? <div style={{ display: 'flex', flexDirection: 'column', gap: '23px', backgroundColor: '#FFFFFF', position: 'fixed', top: '50%', left: 'calc(50% + 240px + 34px)', width: '204px', height: '301px', borderRadius: '8px', padding: '24px' }}>
                <AppQrCode width={140} height={140} />
                <div>
                    <div style={{ fontWeight: '700', lineHeight: '33.6px', textAlign: 'left', fontSize: '24px', paddingBottom: '12px' }}>
                        파지티브호텔 앱에서<br />
                        쉽고 편한 수업 예약을<br />
                        시작해보세요.</div>
                    <div className='body/b2 secondary/s600'>QR코드를 스캔해보세요</div>
                </div>
            </div >
            : <CacheService />}
    </div>;
};

export default MainLayout;