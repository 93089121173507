import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { TopBar } from "../../widgets";

const PopupSection = () => {
    const navigate = useNavigate();
    const [displayStatus, setDisplayStatus] = useState<"none" | "flex">("none");
    const image1 = new Image();
    image1.src = `https://img.positivehotel.io/2024/12/17/0402-detail-1.webp`;
    image1.onload = () => { setDisplayStatus("flex") }
    const image2 = new Image();
    image2.src = `https://img.positivehotel.io/2024/12/17/0402-detail-2.webp`;
    const image3 = new Image();
    image3.src = `https://img.positivehotel.io/2024/12/17/0402-location-1.webp`;
    const image4 = new Image();
    image4.src = `https://img.positivehotel.io/2024/12/17/0402-location-2.webp`;
    return <>
        <TopBar title="팝업 세션" rightIcon={false} />
        <img alt="" src={image1.src} width="100%" />
        <img alt="" src={image2.src} width="100%" />
        <div style={{ backgroundColor: "#FFFFFF", color: "#FFFFFF", padding: "0 20px", textAlign: "center", display: displayStatus, flexDirection: "column", gap: "24px", fontSize: "16px", fontWeight: "600" }}>
            <div style={{ backgroundColor: "#000000", borderRadius: "4px", padding: "16px 0px" }} onClick={() => navigate("/lecture/12511")}>4월 13일 (토) 10:00 세션 예약</div>
            <div style={{ backgroundColor: "#000000", borderRadius: "4px", padding: "16px 0px" }} onClick={() => navigate("/lecture/12519")}>4월 20일 (토) 10:00 세션 예약</div>
            <div style={{ backgroundColor: "#000000", borderRadius: "4px", padding: "16px 0px" }} onClick={() => navigate("/lecture/12520")}>4월 26일 (금) 20:00 세션 예약</div>
        </div>
        <img alt="" src={image3.src} width="100%" />
        <img onClick={() => navigate("/center/2")} alt="" src={image4.src} width="100%" />
    </>
}


export default PopupSection;