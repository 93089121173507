import { Fragment, useEffect, useRef, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { YogaCenterSchedule } from "../";
import { useMyContext } from "../../entities/context";

interface IProps {
    yogaCenterDetail: IYogaCenterDetail;
    style?: React.CSSProperties;
}
const HEADERHEIGHT = 40;
const anchorHeaderStyle = {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: `${HEADERHEIGHT}px`,
    flex: 1,
    cursor: 'pointer'
}
const activeHeaderStyle = {
    color: 'var(--baseblack)',
    borderBottom: '2px solid black',
    ...anchorHeaderStyle
}
const defaultHeaderStyle = {
    color: 'var(--secondarys600)',
    borderBottom: '1px solid #F4F4F4',
    ...anchorHeaderStyle
}

const CustomAnchor = ({ yogaCenterDetail, style }: IProps) => {
    const navigate = useNavigate();
    const part1Ref = useRef<HTMLDivElement>(null);
    const part2Ref = useRef<HTMLDivElement>(null);
    const part3Ref = useRef<HTMLDivElement>(null);
    const tabsRef = useRef<HTMLDivElement>(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [activePart, setActivePart] = useState<number>(0);
    const { topHeight } = useMyContext();
    useEffect(() => {
        const onScroll = () => {
            const currentPosition = window.scrollY;
            let newScrollTop = currentPosition <= 0 ? 0 : currentPosition
            setScrollTop(newScrollTop);
            if (part1Ref.current && part2Ref.current && part3Ref.current && tabsRef.current) {
                if (newScrollTop >= part3Ref.current.offsetTop - HEADERHEIGHT - Number(topHeight)) {
                    if (isMobile || Number(topHeight) > 0) {
                        tabsRef.current.style.setProperty('padding-top', `${topHeight}px`);
                    }
                    setActivePart(3)
                } else if (newScrollTop >= part2Ref.current.offsetTop - HEADERHEIGHT - Number(topHeight)) {
                    if (isMobile || Number(topHeight) > 0) {
                        tabsRef.current.style.setProperty('padding-top', `${topHeight}px`);
                    }
                    setActivePart(2)
                } else if (newScrollTop >= part1Ref.current.offsetTop - HEADERHEIGHT - Number(topHeight)) {
                    if (isMobile || Number(topHeight) > 0) {
                        tabsRef.current.style.setProperty('padding-top', `${topHeight}px`);
                    }
                    setActivePart(1)
                } else if (newScrollTop < part1Ref.current.offsetTop - HEADERHEIGHT - 81) {
                    if (isMobile || Number(topHeight) > 0) {
                        tabsRef.current.style.setProperty('padding-top', `${topHeight}px`);
                    }
                    setActivePart(1)
                }
            }
        }
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    useEffect(() => {
        if (window.location.hash === '#part-1') {
            clickTitle(1)
        }
        if (window.location.hash === '#part-2') {
            clickTitle(2)
        }
        if (window.location.hash === '#part-3') {
            clickTitle(3)
        }
    }, []);

    const clickTitle = (id: number) => {
        if (part1Ref.current && part2Ref.current && part3Ref.current) {
            let paddingTop = isMobile ? 29 : 0;
            if (id === 1) window.scrollTo({ top: part1Ref.current.offsetTop - HEADERHEIGHT + 35 + Number(topHeight) - paddingTop, behavior: 'smooth' })
            if (id === 2) window.scrollTo({ top: part2Ref.current.offsetTop - HEADERHEIGHT + 35 + Number(topHeight) - paddingTop, behavior: 'smooth' })
            if (id === 3) window.scrollTo({ top: part3Ref.current.offsetTop - HEADERHEIGHT + 35 + Number(topHeight) - paddingTop, behavior: 'smooth' })
        }
    }
    return <div style={{ marginBottom: '20px', ...style }}>
        <div style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
            <div ref={tabsRef} className="sub-title/st2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', textAlign: 'center' }}>
                <div className="sub-title/st2" style={activePart === 1 ? activeHeaderStyle : defaultHeaderStyle} onClick={() => clickTitle(1)}>소개</div>
                <div className="sub-title/st2" style={activePart === 2 ? activeHeaderStyle : defaultHeaderStyle} onClick={() => clickTitle(2)}>코치</div>
                <div className="sub-title/st2" style={activePart === 3 ? activeHeaderStyle : defaultHeaderStyle} onClick={() => clickTitle(3)}>시간표</div>
            </div>
        </div>

        <div id="part-1" ref={part1Ref} style={{ padding: '24px var(--space20) var(--space20)' }}>
            <div className="title/t2 base/black">소개</div>
            <div className="body/b2 base/black" style={{ backgroundColor: 'var(--secondarys100)', padding: 'var(--space12)', marginTop: 'var(--space16)', whiteSpace: 'pre-wrap', wordBreak: 'keep-all' }}>
                {yogaCenterDetail.notice}
            </div>
        </div>

        <div id="part-2" ref={part2Ref} style={{ padding: 'var(--space20) 0 var(--space20) var(--space20)' }}>
            <div className="title/t2 base/black">코치</div>
            <div style={{ marginTop: 'var(--space16)', overflow: 'auto', whiteSpace: 'nowrap' }}>
                {yogaCenterDetail.teacherList.map(teacher => <Fragment key={teacher.id}>
                    <div onClick={() => navigate(`/teacher/${teacher.id}`)}
                        style={{
                            display: 'inline-block',
                            width: '160px', height: '160px', borderRadius: 'var(--radius4)', marginRight: 'var(--space8)',
                            backgroundImage: `url(${teacher.profileImageUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            position: 'relative',
                            cursor: 'pointer'
                        }}>
                        <div className="sub-title/st1 base/white" style={{ position: 'absolute', width: '167px', left: 20, bottom: 12, }}>
                            {teacher.name}
                        </div>
                    </div>
                </Fragment>)}
            </div>
        </div>

        <div id="part-3" ref={part3Ref} style={{ padding: 'var(--space20)' }}>
            <div style={{ position: 'relative' }}>
                <div className="title/t2 base/black">시간표</div>
                <YogaCenterSchedule data={yogaCenterDetail.schedule} style={{ marginTop: 'var(--space16)' }} />
            </div>
        </div>
    </div>
}
export { CustomAnchor };