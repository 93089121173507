
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CacheService = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                checkCache()
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const checkCache = () => {
        if (!process.env.REACT_APP_DOMAIN) return;
        fetch(process.env.REACT_APP_DOMAIN)
            .then(response => response.text())
            .then(newHtml => {
                const originalHtml = document.documentElement.innerHTML;
                const originalScriptName = getScriptNameFromHtml(originalHtml);
                const newScriptName = getScriptNameFromHtml(newHtml);
                if (originalScriptName !== newScriptName) {
                    navigate(0)
                }
            })
    }

    const getScriptNameFromHtml = (html: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const scriptTags = doc.querySelectorAll('script[defer]');
        const checkedSrcs: string[] = [];

        scriptTags.forEach(script => {
            const src = script.getAttribute('src');
            if (src) {
                checkedSrcs.push(src);
            }
        });
        return checkedSrcs.length > 0 ? checkedSrcs[0] : undefined;
    }

    return <></>
}
export default CacheService