import './index.css';

interface IProps {
    mainContent: string;
    subContent: string;
    styles?: { wrapper?: React.CSSProperties, container?: React.CSSProperties, main?: React.CSSProperties, sub?: React.CSSProperties }
}

const BrandTextCardUi = ({ mainContent, subContent, styles }: IProps) => (
    <div className="brand-text-card-wrapper" style={styles?.wrapper} >
        <div className="brand-text-card-container" style={styles?.container} >
            <div className="brand-text-card-content-container">
                <div className="brand-text-card-main-content" style={styles?.main} >{mainContent}</div>
                <div className="brand-text-card-sub-content" style={styles?.sub} >{subContent}</div>
            </div>
        </div>
    </div>
)

const BrandTextCard = ({ mainContent, subContent, styles }: IProps) => {
    return <BrandTextCardUi
        mainContent={mainContent}
        subContent={subContent}
        styles={styles} />
}

export { BrandTextCard };