import { ReactComponent as BrandArrowRight } from "../../../assets/images/icon/brandArrowRight.svg"
import './index.css';

interface IProps {
    className?: string;
}

interface IUIProps extends IProps {
    scrollToTop: () => void;
}

const ScrollTopBtnUi = ({ className, scrollToTop }: IUIProps) => (
    <div className={`scroll-top-btn-wrapper ${className}`}>
        <div className="scroll-top-btn-container" onClick={scrollToTop}>
            <BrandArrowRight className="scroll-top-btn-icon" width={56} height={56} />
        </div>
    </div>
)



const ScrollTopBtn = ({ className }: IProps) => {
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
    return <>
        <ScrollTopBtnUi className={className} scrollToTop={scrollToTop} />
    </>
}

export { ScrollTopBtn }