import { useNavigate } from "react-router-dom";
import { ReactComponent as BrandPositivehotelyogaLogo } from "../../../../../assets/images/brandPositivehotelyogaLogo.svg"
import './index.css'

const BrandMoHeader = () => {
    const navigate = useNavigate();

    return <>
        <div className='brand-mo-header-wrapper'>
            <div className="brand-mo-header-container">
                <BrandPositivehotelyogaLogo className='brand-mo-header-logo' width={174.16} height={16} fill='#000101' onClick={() => navigate('/brand')} />
                <div className='brand-mo-header-sns-container'>
                    <div className='brand-mo-header-sns-kakao' onClick={() => window.open('https://pf.kakao.com/_xmWxbYG/chat')}>

                    </div>
                    <div className='brand-mo-header-sns-insta' onClick={() => window.open('https://www.instagram.com/positivehotel_yoga')}>

                    </div>
                </div>
            </div>
        </div>
    </>
}

export { BrandMoHeader };