import BrandLayout from '../layout/BrandLayout';
import { Brand } from '../views/sub/brand';

const BrandRoutes = {
    path: '/',
    element: <BrandLayout />,
    children: [
        { path: '/brand', element: <Brand /> },
    ]
};

export default BrandRoutes;