import dayjs from "dayjs";
import 'dayjs/locale/ko'
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message, Radio, RadioChangeEvent, Select, Spin, Tag } from "antd";
import MainFooter from "../../../layout/MainLayout/footer";
import { updateReservationOnCheckIn } from "../../../service";
import { ReactComponent as ColumnLine } from "../../../assets/images/columnLine.svg"
import { TopBar } from "../../../widgets";
import { getMyWellnessLectureListByDateAndTeacherId } from "../../../service/wellnessLecture";
dayjs.locale('ko')

const MyLecture = () => {
    const { id } = useParams();
    const [selectedDate, setSelectedDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [myLectureList, setMyLectureList] = useState<Array<IMyWellnessLectureListByDateAndTeacherIdResponseV2>>([]);
    const [filterMyLectureList, setFilterMyLectureList] = useState<Array<IMyWellnessLectureListByDateAndTeacherIdResponseV2>>([]);
    const [selectYogaCenter, setSelectYogaCenter] = useState<number>(0);
    const navigate = useNavigate();
    const selectDateRef = useRef<HTMLDivElement | null>(null);
    const startDate = dayjs().add(-30, 'd');
    const endDate = dayjs().add(30, 'd');

    useEffect(() => {
        selectDateRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }, []);

    const getMonthDaysForToday = () => {
        const days = [];
        for (let date = startDate; date.isBefore(endDate); date = date.add(1, 'day')) {
            const dayObject = {
                day: date.format('ddd'),
                date: date.date(),
                dayjs: date.format("YYYY-MM-DD")
            };
            days.push(dayObject);
        }
        return days;
    };

    const monthDays = getMonthDaysForToday();

    useEffect(() => {
        requestMyLectureByTeacherId();
        setSelectYogaCenter(0);
    }, [selectedDate])

    const requestMyLectureByTeacherId = async () => {
        if (!id) return;
        setIsLoading(true);
        const res = await getMyWellnessLectureListByDateAndTeacherId(dayjs(selectedDate).format("YYYY-MM-DDT00:00:00Z"), dayjs(selectedDate).format("YYYY-MM-DDT23:59:59Z"), id);
        setMyLectureList(res.data.filter((i) => (i.reservationInfoList.length > 0) || !i.isDelete));
        setFilterMyLectureList(res.data.filter((i) => (i.reservationInfoList.length > 0) || !i.isDelete));
        setIsLoading(false);
    }

    return <>
        <TopBar title="내 수업 확인" rightIcon={false} />

        <div style={{ display: "flex", flexDirection: "column", gap: "24px", marginTop: "14px", }} >
            <div style={{ fontWeight: "700", fontSize: "18px", paddingLeft: "20px", color: "#111" }}>
                날짜 선택
            </div>
            <div style={{ display: "flex", paddingLeft: "20px", overflow: "auto", whiteSpace: "nowrap" }}>
                {monthDays.map((day, idx) =>
                    <div key={idx}
                        ref={dayjs().format("YYYY-MM-DD") === day.dayjs ? selectDateRef : null}
                        onClick={() => setSelectedDate(day.dayjs)} style={{ padding: "3%", backgroundColor: selectedDate === day.dayjs ? "#FFF743" : undefined, borderRadius: "45%", width: '45px', gap: "6px", cursor: "pointer" }}>
                        <div style={{ fontSize: "12px", fontWeight: "500", textAlign: "center", width: '25px' }}>
                            {day.dayjs === dayjs().format("YYYY-MM-DD") ? <>오늘</> : <>{day.day}</>}
                        </div>
                        <div style={{ fontSize: "16px", fontWeight: "700", textAlign: "center", marginTop: '5px' }}>
                            {day.date}
                        </div>
                    </div>)}
            </div>
        </div >
        <div style={{ background: "#F4F4F4", height: "6px", margin: "24px 0 " }}></div>
        <div style={{ display: "flex", gap: "24px", flexDirection: "column", padding: '0 20px 20px 20px' }}>
            {isLoading ? <div style={{ textAlign: 'center' }}><Spin /></div> :
                filterMyLectureList.filter((myWellnessLectureListByDateAndTeacherIdResponseV2: IMyWellnessLectureListByDateAndTeacherIdResponseV2) => dayjs(myWellnessLectureListByDateAndTeacherIdResponseV2.startDateTime).format('YYYY-MM-DD') === selectedDate).length === 0 ?
                    <>
                        <div>
                            <Radio.Group
                                buttonStyle="solid"
                                size="large"
                                style={{ width: '100%', textAlign: 'center', fontWeight: '400', paddingBottom: "30px" }}
                                onChange={(e: RadioChangeEvent) => { setSelectYogaCenter(e.target.value); setFilterMyLectureList(myLectureList.filter((i) => e.target.value === 0 ? true : i.yogaCenterId === e.target.value)) }}
                                defaultValue={selectYogaCenter}>
                                <Radio.Button value={0} style={{ width: '25%', fontSize: '13px', borderRadius: 1 }}>전체</Radio.Button>
                                <Radio.Button value={1} style={{ width: '25%', fontSize: '13px', borderRadius: 1 }}>강남</Radio.Button>
                                <Radio.Button value={2} style={{ width: '25%', fontSize: '13px', borderRadius: 1 }}>도산</Radio.Button>
                                <Radio.Button value={3} style={{ width: '25%', fontSize: '13px', borderRadius: 1 }}>선릉</Radio.Button>
                            </Radio.Group>
                        </div>
                        <div style={{ textAlign: "center", fontSize: "14px" }}>
                            해당 날짜의 내 수업이 없습니다.
                        </div>
                    </>
                    : <>
                        <Radio.Group
                            buttonStyle="solid"
                            size="large"
                            style={{ width: '100%', textAlign: 'center', fontWeight: '400', paddingBottom: "30px" }}
                            onChange={(e: RadioChangeEvent) => { setSelectYogaCenter(e.target.value); setFilterMyLectureList(myLectureList.filter((i) => e.target.value === 0 ? true : i.yogaCenterId === e.target.value)) }}
                            defaultValue={selectYogaCenter}>
                            <Radio.Button value={0} style={{ width: '25%', fontSize: '13px', borderRadius: 1 }}>전체</Radio.Button>
                            <Radio.Button value={1} style={{ width: '25%', fontSize: '13px', borderRadius: 1 }}>강남</Radio.Button>
                            <Radio.Button value={2} style={{ width: '25%', fontSize: '13px', borderRadius: 1 }}>도산</Radio.Button>
                            <Radio.Button value={3} style={{ width: '25%', fontSize: '13px', borderRadius: 1 }}>선릉</Radio.Button>
                        </Radio.Group>
                        {filterMyLectureList.map((myWellnessLectureListByDateAndTeacherIdResponseV2: IMyWellnessLectureListByDateAndTeacherIdResponseV2, idx) => dayjs(myWellnessLectureListByDateAndTeacherIdResponseV2.startDateTime).format('YYYY-MM-DD') === selectedDate &&
                            <div key={idx} style={{ borderBottom: "1px solid #EBEBEB" }}>
                                <div style={{ display: "flex", alignItems: "flex-start", gap: "16px", paddingBottom: "24px", cursor: 'pointer' }} onClick={() => navigate(`/lecture/${myWellnessLectureListByDateAndTeacherIdResponseV2.id}`)}>
                                    <img style={{ width: "19%", borderRadius: "50%" }} alt="" src={myWellnessLectureListByDateAndTeacherIdResponseV2.teacherProfileImageUrl} />
                                    <div style={{ width: "100%", display: "flex", gap: "16px", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px" }}>
                                            <div style={{ fontSize: "16px", fontWeight: "700", color: "#111" }}>
                                                <div>
                                                    {dayjs(myWellnessLectureListByDateAndTeacherIdResponseV2.startDateTime).format('HH:mm') + " ~ " + dayjs(myWellnessLectureListByDateAndTeacherIdResponseV2.endDateTime).format('HH:mm')}
                                                </div>
                                                <div>
                                                    {myWellnessLectureListByDateAndTeacherIdResponseV2.wellnessLectureName}
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px", fontSize: "14px", color: "#777" }}>
                                                    <div >
                                                        {myWellnessLectureListByDateAndTeacherIdResponseV2.teacherName} 코치
                                                    </div>
                                                    <div>
                                                        {myWellnessLectureListByDateAndTeacherIdResponseV2.room}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: "right", width: "28%" }}>
                                            <div>
                                                <span style={{ fontSize: "14px", fontWeight: "700" }}>{myWellnessLectureListByDateAndTeacherIdResponseV2.reservationInfoList.filter((i) => i.status === "RESERVATION").length}</span><span style={{ fontSize: "14px", color: "#676767" }}> / {myWellnessLectureListByDateAndTeacherIdResponseV2.maxReservationMember}명</span>
                                            </div>
                                            {myWellnessLectureListByDateAndTeacherIdResponseV2.isDelete && <Tag color="error" style={{ margin: "5px 0 0 0" }}>
                                                삭제
                                            </Tag>}
                                        </div>
                                    </div>
                                </div >

                                {myWellnessLectureListByDateAndTeacherIdResponseV2.reservationInfoList.map((reservation) =>
                                    <div key={reservation.id} style={{ display: "flex", alignItems: "flex-start", gap: "16px", paddingBottom: "24px" }}>
                                        <div style={{ width: '24%' }}></div>
                                        <div style={{ width: '100%' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <div style={{ fontSize: '15px' }}>
                                                    <div>{reservation.memberName}</div>
                                                    <div>{reservation.memberMobile.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</div>
                                                </div>
                                                <div>
                                                    <Select
                                                        style={{ width: '100px' }}
                                                        defaultValue={reservation.checkInStatus ? reservation.checkInStatus : '미정'}
                                                        options={[
                                                            { value: 'CHECK_IN', label: '출석' },
                                                            { value: 'ABSENT', label: '결석' },
                                                            { value: 'CLASS_BEFORE', label: '수업전' },
                                                        ]}
                                                        onChange={(checkInStatus) => updateReservationOnCheckIn({ id: reservation.id, checkInId: reservation.checkInId, checkInStatus: checkInStatus })}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ fontSize: '14px', color: '#777777', marginTop: '5px', display: "flex", gap: "3px", textAlign: "center", alignItems: "center" }}><span>{`${reservation.issuedWellnessTicketName}`}</span> <ColumnLine /> <span>{reservation.issuedWellnessTicketRemainingCnt}회 사용가능</span> <ColumnLine /> <span>{reservation.issuedWellnessTicketRemainingDate}일 남음</span></div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        )}</>}
        </div>
        <MainFooter activeButton="my" />
    </>

}

export default MyLecture;