import { useNavigate } from "react-router-dom";
import { SafeArea } from "../../../../shared";
import { BrandMoBody } from "./body";
import { BrandMoFooter } from "./footer";
import { BrandMoHeader } from "./header";
import { ReactComponent as ChIcon } from "../../../../assets/images/icon/chIcon.svg";
import './index.css'
import dayjs from "dayjs";

const BrandMo = () => {
    const navigate = useNavigate();
    const now = dayjs();
    const targetTime = dayjs('2025-01-20T00:00:00+09:00')
    return <>
        <SafeArea />
        <div className="brand-mo-wrapper">
            <div className="brand-mo-container">
                <BrandMoHeader />
                <BrandMoBody />
                <BrandMoFooter />
                <div className="brand-mo-link-btn-container">
                    <div className="brand-mo-link-event-btn-container" onClick={() => navigate(now.isBefore(targetTime) ? '/event/11' : '/event/13')} >
                        <div className="brand-mo-link-event-btn-title caption/c1">
                            신규회원
                        </div>
                        <div className="brand-mo-link-event-btn-content title/t2">
                            50<span className="sub-title/st1">%</span>
                        </div>
                    </div>
                    <div className="brand-mo-link-kakao-btn-container" onClick={() => window.open('http://pf.kakao.com/_xmWxbYG/chat')}>
                        <ChIcon className="brand-mo-link-kakao-btn" width={32} height={33} />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export { BrandMo };